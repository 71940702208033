@import '~@m12s/component-library/dist/fonts.css';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
}

@media (max-height: 540px) {
  html,
  body {
    font-size: 14px;
  }
}

@media (min-resolution: 1.5dppx) and (hover: none) and (min-width: 1024px),
  (min-resolution: 1.5dppx) and (hover: none) and (min-height: 1024px) {
  html,
  body {
    font-size: 20px;
  }

  @media (max-height: 540px) {
    html,
    body {
      font-size: 16px;
    }
  }
}

@media (min-width: 1400px), (min-height: 1400px) {
  html,
  body {
    font-size: max(2.5vmin, 16px);
  }
}

#root {
  height: 100%;
  width: 100%;
}

.ReactModalPortal {
  position: relative;
  z-index: 4;
}
