* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans', sans-serif;
}

button {
  font-family: inherit;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-up-dismiss {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}